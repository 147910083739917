import { createStore, StoreStorageType } from '@/store'
import { AuthSession as Session } from '@/app/definitions'

const states: Session = {
  token: undefined
}

const mutations = {
  /**
   * @param {Session} state
   * @param {string} token
   */
  signIn: (state: Session, token: string): void => {
    state.token = token
  },
  /**
   * @param {Session} state
   */
  signOut: (state: Session): void => {
    state.token = undefined
  }
}

const store = createStore<Session>('AuthStore', states, mutations, StoreStorageType.FOREVER)

export default store
