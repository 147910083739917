export default {
  back: 'Voltar',
  registration :{
    title: 'Atualização de Cadastro',
    labels: {
      name: 'Informe seu Nome',
      cpf: 'Informe seu CPF'
    },
    actions: {
      save: 'Salvar',
    },
    error: 'Erro ao salvar seus dados!',
    wait: 'Salvando seus dados'
  },
  account:{
    title: 'Minha Conta',
    close: '',
    yes: 'Sim',
    no: 'Não',
    alertMessage: 'Não esqueça de salvar as alterações antes!',
    alertTitle: 'Tem certeza que deseja voltar?',
    logoutTitle: 'Fazer logout',
    logoutMessage: 'Tem certeza que deseja sair?',
    labels: {
      name: 'Nome',
      cpf: 'CPF',
      cellphone: 'Celular',
      email: 'E-mail'
    }
  },
  addresses: {
    title: 'Meus Endereços',
    back: 'Retornar',
    loading: 'Atualizando endereços',
    labels: {
      zip: 'CEP',
      state: 'Estado',
      city: 'Cidade',
      neighbourhood: 'Bairro',
      address: 'Local',
      number: 'Numero',
      complement: 'Complemento',
      reference: 'Ponto de Referencia'
    },
    actions: {
      add: 'Adicionar Endereço',
      edit: 'Editar Endereço',
      save: 'Salvar Alterações',
      confirm: 'Confirmar',
      new: 'Novo Endereço',
      error: 'Erro ao salvar endereço',
      manage: 'Gerenciar Endereços'
    },
    messages: {
      emptyAddresses: 'Ainda não temos nenhum endereço aqui',
      add: 'Adicionar Endereço',
      edit: 'Editar Endereço',
      foundAddresses: 'Encontramos alguns endereços que coincidem com sua localização',
      missing: 'Meu endereço não está na lista',
      title: 'Informe seu endereço'
    }
  },
  cards: {
    title: 'Meus Cartões',
    back: 'Retornar',
    loading: 'Carregando cartões',
    messages:{
      emptyCards: 'Você ainda não possui nenhum cartão cadastrado',
      add: 'Adicionar Cartão',
    },
    actions: {
      manage: 'Gerenciar Cartões',
      new: 'Novo Cartão',
      add: 'Adicionar Cartão',
      error: 'Erro ao adicionar cartão'
    },
    labels: {
      ownerName: 'Nome do Titular',
      ownerCPF: 'CPF do Titular',
      number: 'Número',
      expiration: 'Expiração (dd/yyyy)',
      cvv: 'CVV'
    }
  }
}
