import { createI18n } from 'vue-i18n'
import ptBr from './messages/pt-br'

const i18n = createI18n({
  locale: 'pt-br',
  fallbackLocale: 'pt-br',
  messages: {
    'pt-br': ptBr
  }
})

export default i18n
