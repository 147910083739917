import { createStore } from '@/store'

type ToastSettings = {
  visible: boolean
  color: string
  message: string
}

const states: ToastSettings = {
  visible: false,
  color: '',
  message: ''
}

const show = (state: ToastSettings, message: string) => {
  state.visible = true
  state.message = message
}

const mutations = {
  show,
  primary: (state: ToastSettings, message: string) => {
    show(state, message)
    state.color = 'primary'
  },
  secondary: (state: ToastSettings, message: string) => {
    show(state, message)
    state.color = 'secondary'
  },
  tertiary: (state: ToastSettings, message: string) => {
    show(state, message)
    state.color = 'tertiary'
  },
  success: (state: ToastSettings, message: string) => {
    show(state, message)
    state.color = 'success'
  },
  warning: (state: ToastSettings, message: string) => {
    show(state, message)
    state.color = 'warning'
  },
  danger: (state: ToastSettings, message: string) => {
    show(state, message)
    state.color = 'danger'
  },
  light: (state: ToastSettings, message: string) => {
    show(state, message)
    state.color = 'light'
  },
  medium: (state: ToastSettings, message: string) => {
    show(state, message)
    state.color = 'medium'
  },
  dark: (state: ToastSettings, message: string) => {
    show(state, message)
    state.color = 'dark'
  },
  hide: (state: ToastSettings) => {
    state.visible = false
    state.color = ''
    state.message = ''
  }
}

const store = createStore<ToastSettings>('ToastStore', states, mutations)

export default store
