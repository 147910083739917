import { io, Socket } from 'socket.io-client'

const endpoint = 'https://socket.tigoo.com.br'

type Listener = (...args: unknown[]) => void

let socket: Socket
let credentials: Record<string, unknown> | undefined

/**
 * @return {Socket}
 */
export function instance (): Socket {
  if (!endpoint) {
    throw new Error('Configure the socket\'s endpoint')
  }

  if (!socket) {
    socket = io(endpoint)
  }
  return socket
}

/**
 * @param {string} event
 * @param {*} data
 * @return {*}
 */
export function emit (event: string, data: unknown) {
  if (!endpoint) {
    return
  }
  return instance().emit(event, data)
}

/**
 * @param {string} event
 * @param {Listener} listener
 * @return {*}
 */
export function on (event: string, listener: Listener) {
  if (!endpoint) {
    return
  }
  return instance().on(event, listener)
}

/**
 * @param {string} event
 * @return {*}
 */
export function off (event: string) {
  if (!endpoint) {
    return
  }
  return instance().off(event)
}

/**
 * @param {{id: string|number, username: string, name: string}} session
 */
export function register (session: Record<string, unknown>) {
  if (!endpoint) {
    return
  }
  credentials = session
  instance().emit('register', credentials)
}

/**
 */
export function unregister () {
  if (!endpoint) {
    return
  }
  credentials = undefined
  instance().emit('unregister')
}
