export default {
  signIn: {
    title: 'Entrar',
    submit: 'Enviar código',
    username: {
      placeholder: 'Informe seu celular'
    },
    error: 'Erro ao processar sua solicitação',
    help: 'Você receberá um SMS com o código para acessar'
  },
  confirmCode: {
    title: 'Confirmar',
    submit: 'Continuar',
    label: 'Informe o código de 6 dígitos que foi enviado para {username}',
    notReceived: 'Não recebi ou preciso de outro código',
    fail: 'O código informado não é válido'
  }
}
