export default {
  type: 'Restaurante',
  minAmount: 'Pedido Mínimo',
  noData: 'Nenhum item encontrado',
  closed: 'Fechado agora',
  open: 'abre {name} às {hours}',
  later: 'abre mais tarde às {hours}',
  loading: 'Preparando experiência...',
  month:{
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  },
  week: {
    0: 'Domingo',
    1: 'Segunda-feira',
    2: 'Terça-feira',
    3: 'Quarta-feira',
    4: 'Quinta-feira',
    5: 'Sexta-feira',
    6: 'Sábado',
    7: 'Domingo',
  },
  tabs: {
    home: 'Início',
    orders: 'Pedidos',
    profile: 'Perfil'
  },
  products: {
    order: {
      title: 'Peça já',
      add: 'Adicionar',
      update: 'Atualizar',
      close: 'Voltar',
      observation: {
        label: 'Alguma observação?',
        placeholder: 'ex.: favor separar o camarão'
      }
    }
  },
  search: {
    label: 'Pesquisar'
  },
  'shopping-cart': {
    index: {
      title: 'Sacola de Compras',
      header: 'Confira sua Sacola e use os botões ⋮ para fazer alterações nos itens',
      total: 'Total',
      action: 'Finalizar Pedido'
    },
    indicator: {
      action: 'Ver sacola'
    },
    item: {
      action: 'Opções',
      remove: 'Remover item da sacola',
      edit: 'Revisar este item',
      cancel: 'Fechar',
      observation: 'Observação:'
    }
  },
  homeProductsComplement:{
    min: 'Mínimo',
    max: 'Máximo',
    chooseOption: 'Escolha sua opção',
    chooseOptions: 'Escolha suas opções',
    choose: 'Escolha até',
    options: 'Opções'
  }
}
