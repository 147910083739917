import { createStore } from '@/store'
import { Address, Category, CategoryItem, Establishment, Payment, Shift } from '@/app/definitions'

const states: Establishment = {
  id: 0,
  name: '',
  slug: '',
  min: 0,
  average: '',
  logo: '',
  ordering: false,
  storeWindow: false,
  shifts: [],
  payments: [],
  categories: [],
  filter: '',
  address: {
    zip: '',
    neighbourhood: '',
    place: '',
    number: '',
    complement: '',
    city: {
      name: ''
    },
    state: {
      name: '',
      initials: ''
    }
  }
}

let __categories: Category[] = []

const mutations = {
  setId: (state: Establishment, id: string | number) => {
    state.id = id
  },
  setName: (state: Establishment, name: string, pipe?: string): void => {
    state.name = name
    let title = name
    if (pipe) {
      title = `${name} | ${pipe}`
    }
    document.title = title
  },
  setSlug: (state: Establishment, slug: string) => {
    state.slug = slug
  },
  setMin: (state: Establishment, min: number) => {
    state.min = min
  },
  setAverage: (state: Establishment, average: string) => {
    state.average = average
  },
  setLogo: (state: Establishment, logo: string) => {
    state.logo = logo
  },
  setOrdering: (state: Establishment, ordering: boolean) => {
    state.ordering = ordering
  },
  setShifts: (state: Establishment, shifts: Shift[]) => {
    state.shifts = shifts
  },
  setPayments: (state: Establishment, payments: Payment[]) => {
    state.payments = payments
  },
  setAddress: (state: Establishment, address: Address) => {
    state.address = address
  },
  setCategories: (state: Establishment, categories: Category[]) => {
    state.categories = categories
    __categories = state.categories
  },
  setStoreWindow: (state: Establishment, storeWindow: boolean) => {
    state.storeWindow = storeWindow
  },
  filter: (state: Establishment, text: string) => {
    state.filter = text

    if (!text) {
      state.categories = __categories
      return
    }

    const filterByProducts = (categories: Category[], category: Category) => {
      const search = text.toLowerCase()
      if (category.name.toLowerCase().includes(search)) {
        categories.push({ ...category })
        return categories
      }

      const products = category.items.filter((categoryItem: CategoryItem) => {
        const name = categoryItem.product.name.toLowerCase()
        const description = (categoryItem.product.description ?? '').toLowerCase()
        return name.includes(search) || description.includes(search)
      })
      if (products.length === 0) {
        return categories
      }

      categories.push({ ...category, items: products })
      return categories
    }
    state.categories = __categories.reduce(filterByProducts, [])
  },
  clear: (state: Establishment): void => {
    state.name = ''
    state.min = 0
    state.average = ''
    state.ordering = false
    state.storeWindow = false
    state.logo = ''
    state.shifts = []
    state.categories = []
  }
}

const store = createStore<Establishment>('EstablishmentStore', states, mutations)

export default store
