// https://api.tigoo.com.br:444/api
import axios from 'axios'
import AuthStore from '@/app/store/auth'

const http = axios.create({
  baseURL: 'https://api.tigoo.com.br:4545/api'
})

http.interceptors.request.use(
  async function (config) {
    const token = AuthStore.state.token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      return config
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

export default http
