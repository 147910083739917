// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Record<K extends keyof any, T> = {
  [P in K]: T
}

export type Establishment = {
  id: string | number
  name: string
  address: Address
  slug: string
  min: number
  average: string
  logo: string
  ordering: boolean
  storeWindow: boolean
  shifts: Shift[]
  categories: Category[]
  payments: Payment[]
  filter?: string
}

export type Shift = {
  weekday: number
  begin: string
  end: string
}

export type Category = {
  id: string | number
  name: string
  description?: string
  items: CategoryItem[]
}

export type NestedCategory = {
  id: string | number
  name: string
  description?: string
  nested?: NestedCategory[]
}
export type ProductImage = {
  caption?: string,
  url: string,
}
export type Product = {
  id: string | number
  name: string
  active?: boolean
  code?: number | string | null
  description?: string
  image?: string
  images?: ProductImage[]
  cover?: string
  order?: number
  pizza?: boolean
  price?: number
  type?: string
  updatedAt?: string
}

export type CategoryItem = {
  product: Product
  complements?: CategoryItemComplement[]
}

export type CategoryItemComplement = {
  id: string | number
  name: string
  active: boolean
  details: CategoryItemComplementDetail[]
  code?: number | string | null
  order?: number
  max?: number
  min?: number
}

export type CategoryItemComplementDetail = {
  id: string | number
  name: string
  active: boolean
  type: string
  price?: number
  code?: number | string
  description?: string
  order?: number
  amount?: number
  productId?: unknown
}

export type ComplementEntry = Record<number | string, number | string>

export type ComplementEntries = Record<number | string, ComplementEntry>

export type ShoppingCartItemComplement = {
  complementId: string | number
  unitary: number
  total: number
  quantity: number
  detail: CategoryItemComplementDetail
}

export type ShoppingCartItem = {
  categoryItem?: CategoryItem
  id?: string
  unitary?: number
  quantity?: number
  amount?: number
  observation?: string
  items?: ShoppingCartItemComplement[]
}

export enum Modality {
  DELIVERY = 'delivery',
  TAKEAWAY = 'takeaway'
}

export enum Status {
  INPROGRESS = 'inprogress',
  COMPLETED = 'concluded',
  CANCELLED = 'canceled',
  CONFIRMED = 'confirmed',
  CANCELLATION_REQUESTED = 'cancellation-requested',
  CANCELLATION_REQUEST_FAILED = 'cancellation-request-failed',
  INTEGRATED = 'integrated',
  DISPATCHED = 'dispatched',
}

export enum PaymentType {
  UNKNOWN = 'unknown',
  ON_DELIVERY = 'on-delivery',
  CREDIT_CARD = 'credit-card',
  PIX = 'pix',
  ONLINE = 'online'
}

export interface ShoppingCart {
  modality: Modality
  paymentType: PaymentType
  fee: number,
  change?: number
  items: ShoppingCartItem[]
  address?: Address
  payment?: Payment
  card?: Card
}

export interface Order extends ShoppingCart {
  id: string | number
  status: Status,
  date: Date
  info?: string
  finishDate?: Date
}

export interface Orders {
  orders: Order[]
}

export type Payment = {
  id?: number | string
  name: string
  allowChange: boolean
}

export type Address = {
  id?: number | string
  default?: boolean
  zip: string
  neighbourhood: string
  place: string
  number: string
  complement: string
  city: City
  state: State
  location?: Location
  reference?: string
}

export type City = {
  id?: number | string
  name: string
}

export type State = {
  id?: number | string
  name: string
  initials: string
}

export type Location = {
  lat: number | string
  lng: number | string
}

export type Card = {
  id?: number | string,
  default?: boolean
  number: string
  brand: string
  token?: string
}

export type CreditCard = {
  owner: string
  number: string
  cpf: string,
  brand: string
  expiration: string
  cvv: string
  token?: string
}

export type User = {
  id?: number | string,
  name: string
  email: string
  cpf?: string,
  cellphone: string
  addresses: Address[],
  cards: Card[]
}

export type AuthSession = {
  token?: string
}

export type UserSession = {
  user?: User
}
