import { Status } from '@/app/definitions'

export default {
  inProgress: 'Em Andamento',
  finished: 'Finalizados',
  items: 'Itens',
  wait: 'Aguarde...',
  error: {
    header: 'Ops, ocorreu um erro!',
    message: 'Houve um erro ao registrar o pedido. Por favor reveja e tente novamente!',
    button: 'Fechar'
  },
  changeTitle: 'Precisa de Troco?',
  change: 'Troco pra quanto?',
  saving: 'Salvando o seu pedido!',
  changeActions: {
    accept: 'OK',
    decline: 'Não preciso'
  },
  detail: {
    total: 'Total',
    subtotal: 'Subtotal',
    fee: 'Taxa de Entrega'
  },
  orderHistory: {
    order: 'Pedido',
    showOrder: 'Visualizar Pedido'
  },
  orderDetail: {
    title: 'Detalhes do Pedido',
    takeinDate: 'Finalizado em',
    deliveryAddress: 'Endereço de Entrega',
    payment: 'Pagamento',
    takeaway: 'Retirado no estabelecimento'

  },
  statuses: {
    [Status.INPROGRESS]: 'Em progresso',
    [Status.COMPLETED]: 'Finalizado',
    [Status.CANCELLED]: 'Cancelado',
    [Status.DISPATCHED]: 'Saiu para entrega',
    [Status.CANCELLATION_REQUESTED]: 'Cancelamento solicitado',
    [Status.CANCELLATION_REQUEST_FAILED]: 'O pedido já está sendo preparado',
    [Status.INTEGRATED]: 'Recebido pelo restaurante',
    [Status.CONFIRMED]: 'Pedido confirmado pelo restaurante'
  },
  info: {
    label: 'Pedido',
    status: {
      integrated: 'Pedido recebido pelo estabelecimento, aguarde confirmação',
      confirmed: 'Pedido confirmado pelo estabelecimento',
      dispatched: 'Seu pedido está indo até você',
      ready_to_deliver: 'Pedido pronto para ser retirado no estabelecimento',
      cancellation_requested: 'Cancelamento solicitado',
      concluded: 'Pedido concluído',
      cancelled: 'Pedido cancelado'
    }
  }
}
