
import { IonApp, IonRouterOutlet, IonToast } from '@ionic/vue'
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { bootstrap } from '@/service'

import EstablishmentStore from '@/app/store/establishment'
import UserStore from '@/app/store/user'

import ToastStore from '@/app/store/toast'
import { getUser } from '@/service/api/users'

/**
 * @param {string} title
 */
const updateEstablishment = async (title: string) => {
  const slug = useRoute().params.slug
  if (!slug || slug === 'default') {
    return
  }

  const response = await bootstrap(String(slug))

  EstablishmentStore.commit('setId', response.id)
  EstablishmentStore.commit('setName', response.name, title)
  EstablishmentStore.commit('setSlug', slug)
  EstablishmentStore.commit('setMin', response.min)
  EstablishmentStore.commit('setAverage', response.average)
  EstablishmentStore.commit('setLogo', response.logo)
  EstablishmentStore.commit('setOrdering', response.ordering)
  EstablishmentStore.commit('setStoreWindow', response.storeWindow)
  EstablishmentStore.commit('setShifts', response.shifts)
  EstablishmentStore.commit('setPayments', response.payments)
  EstablishmentStore.commit('setAddress', response.address)

  const updateCategories = () => EstablishmentStore.commit('setCategories', response.categories)
  setTimeout(updateCategories, 500)
}

/**
 */
const updateSession = async () => {
  try {
    const user = await getUser()
    UserStore.commit('setUser', user)
  } catch (e) {
    UserStore.commit('setUser', undefined)
  }
}

export default defineComponent({
  /**
   */
  name: 'App',
  /**
   */
  components: {
    IonApp,
    IonRouterOutlet,
    IonToast
  },
  /**
   */
  computed: {
    /**
     */
    key () {
      return this.$route.fullPath
    }
  },
  /**
   */
  setup () {
    const toastVisible = computed(() => ToastStore.state.visible)
    const toastColor = computed(() => ToastStore.state.color)
    const toastMessage = computed(() => ToastStore.state.message)
    const setToastVisibility = (visible: boolean, message = '') => {
      if (visible) {
        ToastStore.commit('show', message)
        return
      }
      ToastStore.commit('hide')
    }

    return {
      toastVisible,
      toastColor,
      toastMessage,
      setToastVisibility
    }
  },
  /**
   */
  async mounted () {
    const { t: $t } = useI18n()
    const title = $t('app.title')
    await updateEstablishment(title)
    await updateSession()
  }
})
