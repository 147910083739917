import { createStore } from '@/store'
import { Address, Card, CreditCard, User, UserSession as Session } from '@/app/definitions'
import { register, unregister } from '@/service/socket'
import EstablishmentStore from './establishment'

const states: Session = {
  user: undefined
}

const mutations = {
  /**
   * @param {Session} state
   * @param {User} user
   */
  setUser: (state: Session, user: User): void => {
    state.user = user

    const username = user?.id

    if (!username) {
      unregister()
      return
    }

    const session = {
      id: EstablishmentStore.state.id,
      username: username,
      name: user?.name
    }
    register(session)
  },
  /**
   * @param {Session} state
   * @param {Card[]} cards
   */
  setCards: (state: Session, cards: Card[]): void => {
    if (!state.user) {
      return
    }
    state.user.cards = cards
  },
  /**
   * @param {Session} state
   * @param {Address[]} addresses
   */
  setAddresses: (state: Session, addresses: Address[]): void => {
    if (!state.user) {
      return
    }
    state.user.addresses = addresses

  },
  /**
   * @param {Session} state
   * @param {Address} address
   */
  addAddress: (state: Session, address: Address): void => {
    const id = (new Date()).getTime().toString(32)
    if (!state?.user?.addresses || !Array.isArray(state?.user?.addresses)) {
      return
    }
    state.user.addresses.push({ id, ...address })
  },
  /**
   * @param {Session} state
   * @param {CreditCard} card
   */
  addCard: (state: Session, card: CreditCard): void => {
    const token = (new Date()).getTime().toString(32)
    if (!state?.user?.cards || !Array.isArray(state?.user?.cards)) {
      return
    }
    state.user.cards.push({ token, ...card })
  },
  /**
   * @param {Session} state
   * @param {Address} address
   */
  updateAddress: (state: Session, address: Address): void => {
    const index = state.user?.addresses.findIndex((item) => address.id === item.id)
    if (!state?.user?.addresses || !Array.isArray(state?.user?.addresses)) {
      return
    }
    if (index === undefined || index < 0) {
      throw new Error('invalid item')
    }

    state.user.addresses.splice(index, 1, { ...JSON.parse(JSON.stringify(address)) })
  },
  /**
   * @param {Session} state
   * @param {string} id
   */
  removeAddress: (state: Session, id: string): void => {
    if (!state?.user?.addresses || !Array.isArray(state?.user?.addresses)) {
      return
    }
    state.user.addresses = state.user.addresses.filter((item: Address) => item.id !== id)
  },
  /**
   * @param {Session} state
   * @param {string} id
   */
  removeCard: (state: Session, id: string): void => {
    if (!state?.user?.cards || !Array.isArray(state?.user?.cards)) {
      return
    }
    state.user.cards = state.user.cards.filter((item: Card) => item.id !== id)
  }
}

const store = createStore<Session>('UserStore', states, mutations)

export default store
