import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:slug/sign-in',
    component: () => import('@/views/Auth/Solicitation.vue')
  },
  {
    path: '/:slug/confirm',
    component: () => import('@/views/Auth/ConfirmCode.vue')
  },
  {
    path: '/:slug/home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/:slug/home-window',
    component: () => import('@/views/HomeWindow.vue')
  },
  {
    path: '/:slug/orders',
    component: () => import('@/views/Orders.vue')
  },
  {
    path: '/:slug/checkout',
    component: () => import('@/views/Checkout.vue')
  },
  {
    path: '/:slug/user/addresses',
    component: () => import('@/views/Account/Addresses.vue')
  },
  {
    path: '/:slug/user/cards',
    component: () => import('@/views/Account/Cards.vue')
  },
  {
    path: '/:slug/payment',
    component: () => import('@/components/checkout/PaymentWayDelivery.vue')
  },
  {
    path: '/:slug/user/registration',
    component: () => import('@/views/Account/Registration.vue')
  },
  {
    path: '/:slug/user',
    component: () => import('@/views/User.vue')
  },
  {
    path: '/:otherwise(.*)*',
    redirect: '/default/home'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
