/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import {
  Address,
  Category,
  CategoryItem,
  CategoryItemComplement,
  CategoryItemComplementDetail,
  Establishment,
  Payment,
  ProductImage,
  Shift
} from '@/app/definitions'

// https://api.tigoo.com.br:444/api
const http = axios.create({
  baseURL: 'https://api.tigoo.com.br:444/api'
})

type ToCategory = (item: Record<string, unknown>) => Category

const toWindowCategory = (): ToCategory => {
  return (item: Record<string, unknown>): Category => {
    const products = item.vitrinesprodutos as Record<string, unknown>[]
    return {
      id: item.id as number,
      name: item.nome as string,
      items: products.map((product: Record<string, unknown>): CategoryItem => {
        const complements = product.complementos as Record<string, unknown>[]
        const productDetail = product.produtos_id as Record<string, unknown>

        let images = product.vitrinesfotos as ProductImage[]
        if (images.length) {
          images = images.map((image: Record<string, unknown>): ProductImage => {
            return {
              url: image.imagem as string,
              caption: image.titulo as string
            }
          })
        }

        let image = ''
        if (productDetail.imagem) {
          image = productDetail.imagem as string
        }
        if (!image && images.length) {
          image = images[0].url
        }

        return {
          product: {
            id: productDetail.id as number,
            name: productDetail.nome as string,
            price: productDetail.preco as number,
            images: images,
            image: image
          },
          complements: complements.map((complement: Record<string, unknown>): CategoryItemComplement => {
            const details = complement.complementositens as Record<string, unknown>[] ?? []
            return {
              id: complement.id as number,
              active: complement.ativo as boolean,
              name: complement.nome as string,
              order: complement.ordem as number,
              min: complement.qtdemin as number,
              max: complement.qtdemax as number,
              details: details.map((detail: Record<string, unknown>): CategoryItemComplementDetail => {
                return {
                  id: detail.id as number,
                  name: detail.nome as string,
                  active: detail.ativo as boolean,
                  type: detail.tipo as string,
                  code: detail.codigo as string,
                  price: detail.preco_venda as number,
                  description: detail.descricao as string
                }
              })
            }
          })
        }
      })
    }
  }
}
/**
 * @param {boolean} ordering
 * @return {{(item: Record<string, unknown>) => Category}}
 */
const toCategory = (): ToCategory => {
  return (item: Record<string, unknown>): Category => {
    const products = item.cardprodutos as Record<string, unknown>[]
    return {
      id: item.id as number,
      name: item.nome as string,
      description: item.descricao as string ?? '',
      items: products.map((item: Record<string, unknown>): CategoryItem => {
        const complements = item.complementos as Record<string, unknown>[]
        const image = item.imagem as string
        return {
          product: {
            id: item.id as number,
            name: item.nome as string,
            description: item.descricao as string,
            price: item.preco as number,
            image: image
          },
          complements: complements.map((item: Record<string, unknown>): CategoryItemComplement => {
            const details = item.complementositens as Record<string, unknown>[]
            return {
              id: item.id as number,
              active: item.ativo as boolean,
              code: item.codigo as string,
              name: item.nome as string,
              order: item.order as number,
              min: item.qtdemin as number,
              max: item.qtdemax as number,
              details: details.map((item: Record<string, unknown>): CategoryItemComplementDetail => {
                return {
                  id: item.id as number,
                  name: item.nome as string,
                  active: item.ativo as boolean,
                  type: item.tipo as string,
                  code: item.codigo as string,
                  price: item.preco_venda as number,
                  description: item.descricao as string
                }
              })
            }
          })
        }
      })
    }
  }
}

/**
 * @param {string} slug
 * @return {Promise<Establishment>}
 */
export async function bootstrap (slug: string): Promise<Establishment> {
  let info
  try {
    info = await http.get(`/restaurante/${slug}/empresa`)
  } catch (e) {
    return {
      slug,
      id: '',
      name: '',
      address: {
        zip: '',
        neighbourhood: '',
        place: '',
        number: '',
        complement: '',
        city: {
          name: ''
        },
        state: {
          name: '',
          initials: ''
        }
      },
      min: 0,
      average: '',
      logo: '',
      ordering: false,
      storeWindow: false,
      shifts: [],
      categories: [],
      payments: [],
      filter: ''
    }
  }

  const company = info?.data?.data[0]

  const id = company?.contas_id
  const name = company?.fantasia ?? '-'
  const min = company?.empresasmenu?.pedidomin ?? 10
  const average = company?.empresasmenu?.tempomedio ?? '40 - 55 min'
  const logo = company?.empresasmenu?.imagem_capa ?? ''
  const ordering = company?.empresasmenu?.habilitapedidos ?? true
  const storeWindow = company?.role !== 'restaurante'

  const address = (company?.empresasenderecos ?? [])
    .filter((endereco: Record<string, any>) => {
      return endereco?.tab_enderecostipos_id?.id === 1
    })
    .map((endereco: Record<string, any>): Address => {
      return {
        id: endereco?.id,
        zip: endereco?.cep ?? '',
        neighbourhood: endereco?.bairro ?? '',
        place: endereco?.endereco ?? '',
        number: endereco?.numero ?? '',
        complement: endereco?.complemento ?? '',
        city: {
          id: endereco?.tab_municipios_id?.id,
          name: endereco?.tab_municipios_id?.municipio ?? ''
        },
        state: {
          id: endereco?.tab_estados_id?.id,
          name: endereco?.tab_estados_id?.nome ?? '',
          initials: endereco?.tab_estados_id?.sigla ?? ''
        }
      }
    })
    .shift()
  let shifts = company?.empresashorarios.map((item: Record<string, unknown>): Shift => {
    const related = item?.tab_semana_id as Record<string, unknown>
    return {
      weekday: Number(related?.id ?? 0),
      begin: String(item.inicio).replace('.000', ''),
      end: String(item.fim).replace('.000', '')
    }
  })
  if (!shifts.length) {
    shifts = [
      {
        weekday: (new Date().getDay()),
        begin: '00:00:00',
        end: '23:59:00'
      }
    ]
  }
  let pw = company?.formaspgto
  if (Array.isArray(pw)) {
    pw = pw.map((formapgto) => {
      return {
        id: formapgto?.id ?? '',
        name: formapgto?.nome ?? '',
        cash: !(formapgto?.cartao ?? false),
        allowChange: formapgto?.permitetroco
      }
    })
  }
  const payments: Payment[] = pw

  let data = []
  try {
    let url = `/vitrine/${slug}/destaques`
    if (!storeWindow) {
      url = `/restaurante/${slug}/menu`
    }
    const menu = await http.get(url)
    data = menu.data?.data ?? []
  } catch (e) {
    // silent is gold
  }

  const categories = data.map(!storeWindow ? toCategory() : toWindowCategory())
  return {
    id,
    name,
    slug,
    address,
    ordering,
    storeWindow,
    min,
    average,
    logo,
    shifts,
    payments,
    categories
  }
}

/**
 * @param {string | number} lat
 * @param {string | number} lng
 * @return {{Promise<any>}}
 */
export async function reverseGeocoder (lat: string | number, lng: string | number): Promise<any> {
  return http.get(`/geolocation/${lat}/${lng}`)
}
