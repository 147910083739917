import http from '../http'
import { OtpSolicitationResponse, User as userApi } from './definitions'
import { Address, Card, CreditCard, User } from '@/app/definitions'

/**
 * @param {string} phone
 * @return {Promise<OtpSolicitationResponse>}
 */
export function otpSolicitation (phone: string): Promise<OtpSolicitationResponse> {
  return http
    .post('/v1/users/otp/request', { identifier: phone })
    .then((response) => response.data.data[0])
}

/**
 * @param {string} id
 * @param {string} code
 * @return {Promise<string>}
 */
export function otpConfirmation (id: string | number, code: string): Promise<string> {
  return http
    .post('/v1/users/otp/confirm', { id: id, code: code })
    .then((response) => response.data.token)
}

/**
 * @return {Promise<User>}
 */
export async function getUser (): Promise<User> {
  const response = await http.get('/v1/auth/user')
  const userApi = response?.data?.data[0]

  const id = userApi?.id
  const name = userApi?.nome ?? undefined
  const cellphone = userApi?.celular ?? ''
  const email = userApi?.email ?? ''
  const cpf = userApi?.cpf ?? ''

  const addresses = (userApi?.usuariosenderecos ?? [])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((endereco: any): Address => {
      return {
        id: endereco?.id,
        zip: endereco?.cep ?? '',
        neighbourhood: endereco?.bairro ?? '',
        place: endereco?.endereco ?? '',
        number: endereco?.numero ?? '',
        complement: endereco?.complemento ?? '',
        city: {
          id: endereco?.id ?? '',
          name: endereco?.cidade ?? ''
        },
        state: {
          id: endereco?.id ?? '',
          name: endereco?.nome ?? '',
          initials: endereco?.uf ?? ''
        }
      }
    })

  const cards = (userApi?.cardtoken ?? [])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((card: any): Card => {
      return {
        id: card?.id,
        number: card?.creditcardnumber ?? undefined,
        brand: card?.creditcardbrand ?? undefined,
        token: card?.creditcardtoken ?? ''
      }
    })

  return {
    id,
    name,
    cpf,
    email,
    addresses,
    cards,
    cellphone
  }
}

/**
 * @param {User} user
 * @return {Promise<string>}
 */
export function saveUser (user: userApi): Promise<string> {
  const payload = {
    nome: user.name,
    cpf: user.cpf,
    cellphone: user.cellphone
  }

  return http.post('/v1/auth/user', payload)
}

/**
 * @param {CreditCard} creditCard
 * @return {Promise<Card>}
 */
export function saveCard (creditCard: CreditCard): Promise<Card> {

  const payload = {
    creditCardHolderName: creditCard.owner,
    creditCardNumber: creditCard.number.replaceAll(' ', ''),
    creditCardExpiryMonth: creditCard.expiration.substring(0, 2),
    creditCardExpiryYear: creditCard.expiration.substring(
      creditCard.expiration.length - 4,
      creditCard.expiration.length
    ),
    creditCardCcv: creditCard.cvv
  }

  return http.post('/v1/auth/user/card', payload)
}

/**
 * @return {Promise<Card[]>}
 */
export async function getCards (): Promise<Card[]> {
  const response = await http.get('/v1/auth/cardtoken')
  const creditCards = response?.data?.data

  return (creditCards ?? [])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((card: any): Card => {
      return {
        id: card?.id,
        number: card?.creditcardnumber ?? '',
        brand: card?.creditcardbrand ?? '',
        token: card?.creditcardtoken ?? ''
      }
    })
}

/**
 * @param {number|string} creditCard
 * @return {Promise<Card>}
 */
export function deleteCard (creditCard: number | string): Promise<Card> {
  return http.delete(`/v1/auth/user/card/${creditCard}`)
}

/**
 * @param {Address} address
 * @return {Promise<Address>}
 */
export function saveAddress (address: Address): Promise<Address> {
  return http.post('/v1/auth/user/address',
    {
      cep: address.zip,
      endereco: address.place,
      numero: address.number,
      complemento: address.complement,
      bairro: address.neighbourhood,
      pontoref: address.reference,
      cidade: address.city.name,
      uf: address.state.initials
    })
}

/**
 * @param {Address} address
 * @return {Promise<Address>}
 */
export function updateAddress (address: Address): Promise<Address> {
  return http.put(`/v1/auth/user/address/${address.id}`,
    {
      cep: address.zip,
      endereco: address.place,
      numero: address.number,
      complemento: address.complement,
      bairro: address.neighbourhood,
      pontoref: address.reference,
      cidade: address.city.name,
      uf: address.state.initials
    })
}

/**
 * @return {Promise<Address[]>}
 */
export async function getAddresses (): Promise<Address[]> {
  const response = await http.get('/v1/auth/address')
  const addresses = response?.data?.data

  return (addresses ?? [])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((endereco: any): Address => {
      return {
        id: endereco?.id,
        zip: endereco?.cep ?? '',
        neighbourhood: endereco?.bairro ?? '',
        place: endereco?.endereco ?? '',
        number: endereco?.numero ?? '',
        complement: endereco?.complemento ?? '',
        city: {
          id: endereco?.id ?? '',
          name: endereco?.cidade ?? ''
        },
        state: {
          id: endereco?.id ?? '',
          name: endereco?.nome ?? '',
          initials: endereco?.uf ?? ''
        }
      }
    })
}

/**
 * @param {number|string} address
 * @return {Promise<Address>}
 */
export function deleteAddress (address: number | string): Promise<Address> {
  return http.delete(`/v1/auth/user/address/${address}`)
}
