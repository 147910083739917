export default {
  title: 'Finalize seu Pedido',
  backToMenu: 'Voltar para o cardápio',
  backToWindow: 'Voltar para a vitrine',
  backToOrder: 'Voltar para finalizar o pedido',
  finish: 'Fazer Pedido',
  sub: 'Subtotal',
  fee: 'Taxa de entrega',
  change: 'Troco para',
  total: 'Total',
  validation:{
    card: {
      header: 'Cartão não informado',
      message: 'Para a forma de pagamento selecionada é necessário informar o cartão. Deseja informar agora?'
    },
    delivery:{
      header: 'Pagamento na entrega não informado',
      message: 'Para a forma de pagamento selecionada é necessário informar como pagará na entrega. Deseja informar agora?'
    },
    online:{
      header: 'Forma de pagamento não informada',
      message: 'Por favor selecione a forma de pagamento antes de continuar'
    },
    address:{
      header: 'Endereço não informado',
      message: 'Para a forma de entrega selecionada é necessário informar o endereço de entrega. Deseja informar agora?'
    },
    actions:{
      yes: 'Sim',
      no: 'Não',
      close: 'Fechar'
    }
  },
  modalityCheckout: {
    title: 'Como seu pedido chegará até você',
    deliverTitle: 'Entrega',
    takeAway: 'Retirada',
    deliveryTime: 'Tempo de preparo',
    actions: {
      changeAddress: 'Alterar endereço',
      chooseAddress: 'Informar endereço',
    },
  },
  paymentCheckout:{
    title: 'Como você deseja pagar?',
    paymentDeliveryTitle: 'Pague na entrega',
    message: 'Formas de pagamento aceitas',
    payment:{
      online: 'Agora, online',
      onDelivery: 'Na entrega'
    },
    paymentWays: {
      title: 'Formas de Pagamento',
      creditCard: 'Cartão de Crédito',
      pix: 'Pix',
      message: 'Na tela a seguir leia o QRCode ou use o Pix Copia e Cola'
    },
    actions: {
      changeCard: 'Alterar Cartão',
      changePaymentWay: 'Alterar Pagamento',
      chooseCard: 'Informar cartão',
      choosePayment: 'Informar pagamento'
    }
  }
}
